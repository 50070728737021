import React from 'react'
import ProjectTemplate from '../../../components/projectTemplate/ProjectTemplate'
import topImage from '../../../assets/projects/antagning/antagningtransparent.png'
import hifi1 from '../../../assets/projects/antagning/hifi1.png'
import hifi2 from '../../../assets/projects/antagning/hifi2.png'
import hifi3 from '../../../assets/projects/antagning/hifi4.png'

function AntagningPage() {
  const projectData = {
    date: 'Spring 2023',
    projectType: 'Front End Development',
    projectHeader: 'The design system Utsökt',
    challenge:
      'I was tasked with starting the creation of a new design system for the national admission service. Since their website needed improvements, it was important to create a central resource where designers and developers could easily access information about the colors, texts, and components used in different places.',
    topImage: topImage,
    carried: 'Summer job 2022, and part time job during the spring of 2023',
    division: 'I did all of the design and development',
    tools: 'Figma, Angular and Visual Studio Code',
    duration: '8 weeks 100%, 5 months 20%',
    mobileHifi: [hifi1, hifi2, hifi3],
    desktopHifi: [hifi1, hifi2, hifi3],
    solution:
      'Result: After receiving feedback from future users during the process, a beginning of the design system was created in Angular with the aim of conveying information in a quick and user-friendly manner. The developers and designers did not want a design system with lots of text; instead, they wanted it to be easy to jump into the system, find the information they needed, and then get back to their work swiftly. However, if someone wants to read more about a particular component, it is neatly organized under distinct tabs.',
  }

  return (
    <ProjectTemplate
      date={projectData.date}
      projectType={projectData.projectType}
      projectHeader={projectData.projectHeader}
      challenge={projectData.challenge}
      topImage={projectData.topImage}
      carried={projectData.carried}
      division={projectData.division}
      tools={projectData.tools}
      duration={projectData.duration}
      mobileHifi={projectData.mobileHifi}
      desktopHifi={projectData.desktopHifi}
      solution={projectData.solution}
    />
  )
}

export default AntagningPage
