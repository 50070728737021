import React, { useEffect, useState } from 'react'
import './HifiContainer.css'

const HifiContainer = ({ mobileHifi, desktopHifi }) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const [image, setImage] = useState(
    screenWidth >= 560 ? desktopHifi : mobileHifi
  )

  const handleResize = () => {
    const newWidth = window.innerWidth
    setScreenWidth(newWidth)
    setImage(newWidth >= 560 ? desktopHifi : mobileHifi)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <>
      <section className='multiple-hifi wide-width'>
        {image.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`hifi prototype ${index}`}
            className='cover-img wide-width'
          />
        ))}
      </section>
    </>
  )
}

export default HifiContainer
