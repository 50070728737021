import React from 'react'
import ProjectTemplate from '../../../components/projectTemplate/ProjectTemplate'
import topImage from '../../../assets/projects/gymtrack/gymtracktransparent.png'
import mobileHifi1 from '../../../assets/projects/gymtrack/hifi-mobil-1.png'
import mobileHifi2 from '../../../assets/projects/gymtrack/hifi-mobil-2.png'
import mobileHifi3 from '../../../assets/projects/gymtrack/hifi-mobil-3.png'
import desktopHifi from '../../../assets/projects/gymtrack/hifi.png'

function GymTrackPage() {
  const projectData = {
    date: 'October 2021',
    projectType: 'UX-design',
    projectHeader: 'The app idea GymTrack',
    challenge:
      'This project falls under the course "Human-Computer Interaction with Emphasis on Cognition and Design." The objective was to conceive an app idea that improves an everyday process involving human interaction with a compouter/machine.',
    topImage: topImage,
    carried:
      'The course Product Development in Media Technology using the "Design-build-test" method',
    division:
      'All five participants were equally involved in all steps of the process',
    tools: 'Figma',
    duration: '4 months, 25%',
    mobileHifi: [mobileHifi1, mobileHifi2, mobileHifi3],
    desktopHifi: [desktopHifi],
    solution:
      'Result: We decided to create an app concept for a virtual personal trainer. This app would have the capability to sync with gym equipment using NFC technology, simplifying the process of monitoring workout sessions and tracking fitness progress.',
  }

  return (
    <ProjectTemplate
      date={projectData.date}
      projectType={projectData.projectType}
      projectHeader={projectData.projectHeader}
      challenge={projectData.challenge}
      topImage={projectData.topImage}
      carried={projectData.carried}
      division={projectData.division}
      tools={projectData.tools}
      duration={projectData.duration}
      mobileHifi={projectData.mobileHifi}
      desktopHifi={projectData.desktopHifi}
      solution={projectData.solution}
    />
  )
}

export default GymTrackPage
