import './Accordion.css'
import { IonIcon } from '@ionic/react'
import { chevronDownOutline } from 'ionicons/icons'
import { useState } from 'react'
import { Link } from 'react-router-dom'

function Accordion({ title, projects }) {
  const [expand, setExpand] = useState(false)
  const toggle = () => setExpand((prevExpand) => !prevExpand)
  console.log(expand)

  return (
    <div class='accordion'>
      <button onClick={toggle}>
        <h2>{title}</h2>
        <IonIcon
          className={`ion-icon ${expand ? 'rotate' : ''}`}
          icon={chevronDownOutline}
        />
      </button>
      <div className={`accordion-content ${expand ? 'expanded' : ''}`}>
        {projects.map((project) => (
          <Link
            to={project.route}
            className='accordion-item'
            key={project.name}
          >
            <img
              src={project.picture}
              className='accordion-img'
              alt='prototype'
            />
            <p>{project.name}</p>
          </Link>
        ))}
      </div>
    </div>
  )
}

export default Accordion
