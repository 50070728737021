import React, { useEffect, useState } from 'react'
import './About.css'
import hack from '../assets/hack.png'
import design from '../assets/design.png'

function About() {
  const [isVisible, setIsVisible] = useState(false)

  const handleScroll = () => {
    const textElement = document.getElementById('testtest')
    if (textElement) {
      const elementPosition = textElement.getBoundingClientRect().top
      const windowHeight = window.innerHeight

      if (elementPosition < windowHeight) {
        setIsVisible(true)
      }
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <>
      <section className='about narrow-width'>
        <img
          src={design}
          alt='Design open prize'
          className='about-image'
          id='hack'
        />
        <div className='about-text'>
          <h1>Why do I study Interaction Technology?</h1>
          <p>
            I have always been interested in technology and design, but my first
            exposure to interaction technology occurred when I participated in
            Design Open, where I also secured the first prize. Design Open is
            the largest design competition for high school students in northern
            Sweden.
          </p>
          <p>
            My submission was an innovative concept for an app designed to
            gather, filter, and present information about nearby restaurants.
            The primary aim of this app was to enhance restaurant experiences
            for individuals with specific requirements.
          </p>
        </div>
      </section>
      <section
        id='testtest'
        className={`about narrow-width slideIn0 ${
          isVisible ? 'slideIn100' : ''
        }`}
      >
        <img
          src={hack}
          alt='Good tech hack prize'
          className='about-image'
          id='hack'
        />
        <div className='about-text'>
          <p>
            After the competition, I felt a desire to continue simplifying
            everyday life through user-friendly technology. This is why I am
            currently in my fifth and final year of studying the Master of
            Science Programme in Interaction Technology and Design at Umeå
            University.
          </p>
          <p>
            Throughout the program, we acquire a comprehensive skill set,
            ranging from backend development to UI design. Throughout this
            period, I have achieved various milestones, one of which was winning
            an award for the best student team at the Good Tech Hack hackathon.
            Additionally, I've been fortunate to engage in NASA's global
            hackathon on two occasions, where my team emerged victorious in the
            local competition both times. Now I'm ready for new adventures!
          </p>
        </div>
      </section>
    </>
  )
}

export default About
