import React from 'react'
import ProjectTemplate from '../../../components/projectTemplate/ProjectTemplate'
import topImage from '../../../assets/projects/weathermap/weathermaptransparent.png'
import hifi from '../../../assets/projects/weathermap/hifi.png'

function WeathermapPage() {
  const projectData = {
    date: 'May 2022',
    projectType: 'Front End Development',
    projectHeader: 'Weather Map, the two API mix',
    challenge:
      'The challenge of this assignment was to create a web application that combined two APIs. We decided to use NASAs weather API and integrate it with a map API offered by Mapbox.',
    topImage: topImage,
    carried: 'During the course "Applications Development for Internet"',
    division:
      'Of two people, I did the most of the UX/UI-design, but also helped with the development',
    tools: 'Figma, React and Visual Studio Code',
    duration: '7 weeks 50%',
    mobileHifi: [hifi],
    desktopHifi: [hifi],
    solution:
      'Result: The application we developed in React allows users to view the average temperature at a specific location around the world for a given month and year. The map, which serves as the background of the website, is interactive and can be moved and zoomed in. The year and month are selected within the white panel and matched with the coordinates on the map. This process retrieves the relevant data from NASAs weather API and presents it to the user.',
  }

  return (
    <ProjectTemplate
      date={projectData.date}
      projectType={projectData.projectType}
      projectHeader={projectData.projectHeader}
      challenge={projectData.challenge}
      topImage={projectData.topImage}
      carried={projectData.carried}
      division={projectData.division}
      tools={projectData.tools}
      duration={projectData.duration}
      mobileHifi={projectData.mobileHifi}
      desktopHifi={projectData.desktopHifi}
      solution={projectData.solution}
    />
  )
}

export default WeathermapPage
