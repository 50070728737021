import React, { useEffect, useState } from 'react'
import HifiContainer from '../hifiContainer/HifiContainer'
import './ProjectTemplate.css'

const ProjectTemplate = ({
  date,
  projectHeader,
  challenge,
  topImage,
  carried,
  division,
  tools,
  duration,
  projectType,
  mobileHifi,
  desktopHifi,
  solution,
}) => {
  const [isVisible, setIsVisible] = useState(false)

  const handleScroll = () => {
    const textElement = document.getElementById('info')
    if (textElement) {
      const elementPosition = textElement.getBoundingClientRect().top
      const windowHeight = window.innerHeight

      if (elementPosition < windowHeight) {
        setIsVisible(true)
      }
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <>
      <section className='project-top narrow-width'>
        <h4 className='project-date'>{date}</h4>
        <div className='project-summary'>
          <div className='project-type'>
            <p>{projectType}</p>
            <div class='line-horizontal'></div>
          </div>
          <h1 className='project-header'>{projectHeader}</h1>
          <p className='project-challenge'>{challenge}</p>
        </div>
        <div className='project-image-container'>
          <img src={topImage} alt='prototype' className='project-image' />
        </div>
      </section>

      <section
        id='info'
        className={`narrow-width slideIn0 ${isVisible ? 'slideIn100' : ''}`}
      >
        <div className='info-box'>
          <h3>Carried out during</h3>
          <p className='small'>{carried}</p>
        </div>
        <div className='info-box'>
          <h3>Division of labor</h3>
          <p className='small'>{division}</p>
        </div>
        <div className='info-box'>
          <h3>Tools used</h3>
          <p className='small'>{tools}</p>
        </div>
        <div className='info-box'>
          <h3>Duration</h3>
          <p className='small'>{duration}</p>
        </div>
      </section>

      <HifiContainer mobileHifi={mobileHifi} desktopHifi={desktopHifi} />

      <section className='narrow-width'>
        <div className='solution-text'>
          <h3>{solution}</h3>
        </div>
      </section>
    </>
  )
}

export default ProjectTemplate
