import React, { useEffect, useState } from 'react'
import './Home.css'
import johanna from '../assets/johanna.png'
import { PiArrowElbowRightDownLight } from 'react-icons/pi'
import { IconContext } from 'react-icons'
import Accordion from '../components/accordion/Accordion'
import uxProjects from './projects/uxProjects/uxProjects'
import frontProjects from './projects/frontProjects/frontProjects'
import appProjects from './projects/appProjects/appProjects'

function Home() {
  const [showAccordion, setShowAccordion] = useState(false)
  const [showImage, setShowImage] = useState(false)

  const handleScroll = () => {
    const textElement = document.getElementById('projects')
    if (textElement) {
      const elementPosition = textElement.getBoundingClientRect().top
      const windowHeight = window.innerHeight

      if (elementPosition < windowHeight) {
        setShowAccordion(true)
      }
    }
    setShowImage(true)
    window.removeEventListener('touchstart', handleScroll)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <>
      <section className='introduction narrow-width'>
        <div className='introduction-text'>
          <h1 className='introduction-header'>
            I am Johanna Lindoff, a Master of Science student in Interaction
            Technology and Design
          </h1>
          <a href='#projects' className='link-projects'>
            <p>See my latest projects</p>
            <IconContext.Provider value={{ size: '2em' }}>
              <div className='icon'>
                <PiArrowElbowRightDownLight />
              </div>
            </IconContext.Provider>
          </a>
        </div>
        <img
          src={johanna}
          className={`fadeImage0 ${
            showImage ? 'fadeImage100' : ''
          } introduction-img`}
          alt='Johanna'
        />
      </section>
      <section
        id='projects'
        className={`slideIn0 ${showAccordion ? 'slideIn100' : ''} narrow-width`}
      >
        <Accordion title={'App development'} projects={appProjects} />
        <Accordion title={'UX/UI-design'} projects={uxProjects} />
        <Accordion title={'Front End Development'} projects={frontProjects} />
      </section>
    </>
  )
}

export default Home
