import antagning from '../../../assets/projects/antagning/antagning.png'
import berg from '../../../assets/projects/berg/berg.png'
import weathermap from '../../../assets/projects/weathermap/weathermap.png'

const frontProjects = [
  {
    name: 'Utsökt, the design system',
    picture: antagning,
    category: 'front-end',
    route: '/antagning',
  },
  {
    name: 'Education portal',
    picture: berg,
    category: 'front-end',
    route: '/berg',
  },
  {
    name: 'Weather Map',
    picture: weathermap,
    category: 'front-end',
    route: '/weathermap',
  },
]

export default frontProjects
