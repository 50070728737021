import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import './App.css'
import './fonts/Inter-Regular.ttf'
import { FaLinkedinIn } from 'react-icons/fa'
import { IconContext } from 'react-icons'
import Home from './pages/Home.js'
import About from './pages/About.js'
import SchysstPage from './pages/projects/uxProjects/SchysstPage'
import GymTrackPage from './pages/projects/uxProjects/GymTrackPage'
import SolwePage from './pages/projects/uxProjects/SolwePage'
import DevityPage from './pages/projects/uxProjects/DevityPage'
import HealthPage from './pages/projects/uxProjects/HealthPage'
import AntagningPage from './pages/projects/frontProjects/AntagningPage'
import BergPage from './pages/projects/frontProjects/BergPage'
import WeathermapPage from './pages/projects/frontProjects/WeathermapPage'
import TabletalkPage from './pages/projects/appProjects/TabletalkPage'

function App() {
  return (
    <>
      <header>
        <nav>
          <a href='/'>
            <h4 className='logo'>JLindoff</h4>
          </a>
          <ul>
            <li>
              <a href='/about'>About</a>
            </li>
            <li>
              <a
                href='https://www.linkedin.com/in/johannalindoff/'
                target='_blank'
              >
                <IconContext.Provider value={{ size: '1.2em' }}>
                  <FaLinkedinIn />
                </IconContext.Provider>
              </a>
            </li>
          </ul>
        </nav>
      </header>
      <main>
        <Router>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/about' element={<About />} />
            <Route path='/gymtrack' element={<GymTrackPage />} />
            <Route path='/schysst' element={<SchysstPage />} />
            <Route path='/solwe' element={<SolwePage />} />
            <Route path='/devity' element={<DevityPage />} />
            <Route path='/antagning' element={<AntagningPage />} />
            <Route path='/berg' element={<BergPage />} />
            <Route path='/weathermap' element={<WeathermapPage />} />
            <Route path='/tabletalk' element={<TabletalkPage />} />
            <Route path='/healthplatform' element={<HealthPage />} />
          </Routes>
        </Router>
      </main>
      <footer></footer>
      <button className='CTA'>
        <a className='light-link' href='mailto:lindoffjohanna@gmail.com'>
          Contact me
        </a>
      </button>
    </>
  )
}

export default App
