import React from 'react'
import ProjectTemplate from '../../../components/projectTemplate/ProjectTemplate'
import topImage from '../../../assets/projects/tabletalk/tabletalktransparent.png'
import mobileHifi1 from '../../../assets/projects/tabletalk/hifi-mobil-1.png'
import mobileHifi2 from '../../../assets/projects/tabletalk/hifi-mobil-2.png'
import mobileHifi3 from '../../../assets/projects/tabletalk/hifi-mobil-3.png'
import desktopHifi from '../../../assets/projects/tabletalk/hifi.png'

function TabletalkPage() {
  const projectData = {
    date: 'Autumn 2022',
    projectType: 'App Development',
    projectHeader: 'The social game Table Talk',
    challenge:
      'During the course, we were divided into groups of 3-4 individuals to develop a mobile prototype. We were allowed to work as we wished during the course, as long as we created Lo-Fi and Hi-Fi prototypes that we tested before developing a final prototype.',
    topImage: topImage,
    carried: 'The course "Prototype Development for Mobile Applications"',
    division:
      'We were four people working on the project. The work was evenly distributed, but I had a little more responsibility for the backend work',
    tools:
      'Figma, React Native, socket.io, Visual Studio Code, GitHub, XCode Simulator',
    duration: '2 months, 50%',
    mobileHifi: [mobileHifi1, mobileHifi2, mobileHifi3],
    desktopHifi: [desktopHifi],
    solution:
      'Result: We undertook the development of a social game designed for pre-parties, family gatherings, or date nights. The uniqueness of the game lies in the concept where everyones phones are placed on the table, collectively forming the game board that displays the questions. This approach also ensures that no one in the group can get distracted by their phones during the game.',
  }

  return (
    <ProjectTemplate
      date={projectData.date}
      projectType={projectData.projectType}
      projectHeader={projectData.projectHeader}
      challenge={projectData.challenge}
      topImage={projectData.topImage}
      carried={projectData.carried}
      division={projectData.division}
      tools={projectData.tools}
      duration={projectData.duration}
      mobileHifi={projectData.mobileHifi}
      desktopHifi={projectData.desktopHifi}
      solution={projectData.solution}
    />
  )
}

export default TabletalkPage
