import tabletalk from '../../../assets/projects/tabletalk/tabletalk.png'

const appProjects = [
  {
    name: 'Table Talk',
    picture: tabletalk,
    category: 'app-development',
    route: '/tabletalk',
  },
]

export default appProjects
