import health from '../../../assets/projects/health/health.png'
import schysst from '../../../assets/projects/schysst/schysst.png'
import gymtrack from '../../../assets/projects/gymtrack/gymtrack.png'
import solwe from '../../../assets/projects/solwe/solwe.png'
import devity from '../../../assets/projects/devity/devity.png'

const uxProjects = [
  {
    name: 'Health platform',
    picture: health,
    category: 'ui-design',
    route: '/healthplatform',
  },
  {
    name: 'Schysst käk',
    picture: schysst,
    category: 'ux-design',
    route: '/schysst',
  },
  {
    name: 'GymTrack',
    picture: gymtrack,
    category: 'ux-design',
    route: '/gymtrack',
  },
  {
    name: 'Solwe',
    picture: solwe,
    category: 'ux-design',
    route: '/solwe',
  },
  {
    name: 'Devity',
    picture: devity,
    category: 'ux-design',
    route: '/devity',
  },
]

export default uxProjects
