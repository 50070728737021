import React from 'react'
import ProjectTemplate from '../../../components/projectTemplate/ProjectTemplate'
import topImage from '../../../assets/projects/health/healthtransparent.png'
import mobileHifi1 from '../../../assets/projects/health/hifi-mobil-1.png'
import mobileHifi2 from '../../../assets/projects/health/hifi-mobil-2.png'
import mobileHifi3 from '../../../assets/projects/health/hifi-mobil-3.png'
import desktopHifi from '../../../assets/projects/health/hifi.png'

function HealthPage() {
  const projectData = {
    date: 'Summer 2023',
    projectType: 'UI-design',
    projectHeader: 'The health platform of 2035',
    challenge:
      'The aim of this project was to spark inspiration for Region Västernorrlands upcoming digitalization initiatives. This involved creating a visualization of healthcare in the year 2035, using visualization techniques according to our own choice. The focus was not on extreme realism, but rather on illustrating an optimistic and idealized future scenario.',
    topImage: topImage,
    carried: 'Summer internship at CGI together with Region Västernorrland',
    division: 'We were two people working on the project together',
    tools: 'Figma',
    duration: '7 weeks',
    mobileHifi: [mobileHifi1, mobileHifi2, mobileHifi3],
    desktopHifi: [desktopHifi],
    solution:
      'Result: The "health platform" is one of three core elements within our future vision. It ties together the other two components: "personal sensors" and the "health station", which allows easy proactive health tests. Combining health station data and personal sensor data, a digital health overview is accessible on the platform and to healthcare professionals for personalized care. Personalized care can also be accomplished through the formation of small health teams, all well-acquainted with you and your medical history.',
  }

  return (
    <ProjectTemplate
      date={projectData.date}
      projectType={projectData.projectType}
      projectHeader={projectData.projectHeader}
      challenge={projectData.challenge}
      topImage={projectData.topImage}
      carried={projectData.carried}
      division={projectData.division}
      tools={projectData.tools}
      duration={projectData.duration}
      mobileHifi={projectData.mobileHifi}
      desktopHifi={projectData.desktopHifi}
      solution={projectData.solution}
    />
  )
}

export default HealthPage
