import React from 'react'
import ProjectTemplate from '../../../components/projectTemplate/ProjectTemplate'
import topImage from '../../../assets/projects/schysst/schyssttransparent.png'
import mobileHifi1 from '../../../assets/projects/schysst/hifi-mobil-1.png'
import mobileHifi2 from '../../../assets/projects/schysst/hifi-mobil-2.png'
import mobileHifi3 from '../../../assets/projects/schysst/hifi-mobil-3.png'
import desktopHifi from '../../../assets/projects/schysst/hifi.png'

function SchysstPage() {
  const projectData = {
    date: 'Spring 2022',
    projectType: 'UX-design',
    projectHeader: 'Cook together, Schysst käk',
    challenge:
      'The focus of this group project was to design, build and test in collaboration with a company. We got in touch with the food company "Schysst käk" and they gave us the following challenge: "The goal is to create a modern recipe application that is easy, funny and does not have any unnecessary stuff. We want unexpected functions and extras all packed in one super simple and functional solution."',
    topImage: topImage,
    carried:
      'The course "Product Development in Media Technology using the Design-build-test method"',
    division:
      'All five participants were equally involved in all steps of the process',
    tools: 'Figma',
    duration: '4 months, 25%',
    mobileHifi: [mobileHifi1, mobileHifi2, mobileHifi3],
    desktopHifi: [desktopHifi],
    solution:
      'Result: After exploring various ideas and trying out different prototypes, we came up with the final result. Our solution, called "Cook Together," helps kids learn cooking in a fun way using gamification. The entire family can participate in the "Kebab Team," engaging in gameplay individually on their respective mobile phones.',
  }

  return (
    <ProjectTemplate
      date={projectData.date}
      projectType={projectData.projectType}
      projectHeader={projectData.projectHeader}
      challenge={projectData.challenge}
      topImage={projectData.topImage}
      carried={projectData.carried}
      division={projectData.division}
      tools={projectData.tools}
      duration={projectData.duration}
      mobileHifi={projectData.mobileHifi}
      desktopHifi={projectData.desktopHifi}
      solution={projectData.solution}
    />
  )
}

export default SchysstPage
