import React from 'react'
import ProjectTemplate from '../../../components/projectTemplate/ProjectTemplate'
import topImage from '../../../assets/projects/berg/bergtransparent.png'
import hifi1 from '../../../assets/projects/berg/hifi1.png'
import hifi2 from '../../../assets/projects/berg/hifi2.png'
import hifi3 from '../../../assets/projects/berg/hifi3.png'

function BergPage() {
  const projectData = {
    date: 'Summer 2022',
    projectType: 'Front End development',
    projectHeader: 'Education portal for Team Berg',
    challenge:
      'Team Berg is an investment community with over 800 members. The education for the members has mainly taken place on discord but it has been difficult for the members to keep track of their progress. Therefore, the community needed a new way of educating their members.',
    topImage: topImage,
    carried: 'Free time',
    division:
      'I carried out the design and development of the platform. Team Berg contributed with the course material.',
    tools: 'Figma, WordPress and the LearnDash plugin',
    duration: '4 months',
    mobileHifi: [hifi1, hifi2, hifi3],
    desktopHifi: [hifi1, hifi2, hifi3],
    solution:
      'Result: The new education platform was created using WordPress and the LearnDash plugin. The platform was integrated with the Memberful service, where Team Berg manages all its members. They log in using their Memberful account, and various members are presented with distinct content on the platform based on their membership type.',
  }

  return (
    <ProjectTemplate
      date={projectData.date}
      projectType={projectData.projectType}
      projectHeader={projectData.projectHeader}
      challenge={projectData.challenge}
      topImage={projectData.topImage}
      carried={projectData.carried}
      division={projectData.division}
      tools={projectData.tools}
      duration={projectData.duration}
      mobileHifi={projectData.mobileHifi}
      desktopHifi={projectData.desktopHifi}
      solution={projectData.solution}
    />
  )
}

export default BergPage
