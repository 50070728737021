import React from 'react'
import ProjectTemplate from '../../../components/projectTemplate/ProjectTemplate'
import topImage from '../../../assets/projects/devity/devitytransparent.png'
import mobileHifi from '../../../assets/projects/devity/hifi-mobil.png'
import desktopHifi from '../../../assets/projects/devity/hifi.png'

function DevityPage() {
  const projectData = {
    date: 'June 2021',
    projectType: 'UI-design',
    projectHeader: 'Devity screen protection',
    challenge:
      'The challenge was to create a website for the fictional startup "Devity". The objective was to showcase their screen serum product that makes screens shockproof and unbreakable. The website needed to reflect their core values encompassing trust, adaptable, customer satisfaction, innovation, modern and sustainable',
    topImage: topImage,
    carried: 'Entrance test for UX/UI-employment',
    division: 'I made the UX/UI-design',
    tools: 'Figma',
    duration: '3 days',
    mobileHifi: [mobileHifi],
    desktopHifi: [desktopHifi],
    solution:
      'Result: The website should particularly reflect their values of trust, customer satisfaction, innovation, and modernity. I chose to work with dark purple and blue tones to convey a sense of modernity and innovation. As the content of the page was already predetermined, I only needed to focus on the placement and design aspects of these elements.',
  }

  return (
    <ProjectTemplate
      date={projectData.date}
      projectType={projectData.projectType}
      projectHeader={projectData.projectHeader}
      challenge={projectData.challenge}
      topImage={projectData.topImage}
      carried={projectData.carried}
      division={projectData.division}
      tools={projectData.tools}
      duration={projectData.duration}
      mobileHifi={projectData.mobileHifi}
      desktopHifi={projectData.desktopHifi}
      solution={projectData.solution}
    />
  )
}

export default DevityPage
