import React from 'react'
import ProjectTemplate from '../../../components/projectTemplate/ProjectTemplate'
import topImage from '../../../assets/projects/solwe/solwetransparent.png'
import mobileHifi1 from '../../../assets/projects/solwe/hifi-mobil-1.png'
import mobileHifi2 from '../../../assets/projects/solwe/hifi-mobil-2.png'
import mobileHifi3 from '../../../assets/projects/solwe/hifi-mobil-3.png'
import desktopHifi from '../../../assets/projects/solwe/hifi.png'

function SolwePage() {
  const projectData = {
    date: 'October 2021',
    projectType: 'UX-design',
    projectHeader: 'The app idea Solwe',
    challenge:
      '"Solwe" stands as our teams submission to the NASA Space App Challenge, a global hackathon hosted by NASA. Our specific challenge involved enhancing the accessibility of NASAs weather data for individuals utilizing solar panels. Representing the Stockholm division, we secured victory in the regional competition.',
    topImage: topImage,
    carried: 'The hackathon "Nasa Space App Challenge"',
    division:
      'In a group of six people, I contributed with the UX-design and development',
    tools: 'Figma and Xcode',
    duration: '3 days',
    mobileHifi: [mobileHifi1, mobileHifi2, mobileHifi3],
    desktopHifi: [desktopHifi],
    solution:
      'Result: Our concept is an app capable of retrieving weather data from NASAs API and intelligently displaying it to the user. Users who have their own solar panels can now plan their electricity production based on historical weather data. Additionally, the app assists users in making more environmentally conscious choices.',
  }

  return (
    <ProjectTemplate
      date={projectData.date}
      projectType={projectData.projectType}
      projectHeader={projectData.projectHeader}
      challenge={projectData.challenge}
      topImage={projectData.topImage}
      carried={projectData.carried}
      division={projectData.division}
      tools={projectData.tools}
      duration={projectData.duration}
      mobileHifi={projectData.mobileHifi}
      desktopHifi={projectData.desktopHifi}
      solution={projectData.solution}
    />
  )
}

export default SolwePage
